import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | LaLaDex',
  defaultTitle: 'LaLaDex',
  description: 'Trade, earn, and own crypto on the all-in-one multichain DEX',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@laladex',
    site: '@laladex',
  },
  openGraph: {
    title: "LaLaDex - Everyone's Favorite DEX",
    description: 'Trade, earn, and own crypto on the all-in-one multichain DEX',
    images: [{ url: '/images/banner/banner_laladex.png' }],
  },
}
